
import moment from 'moment';
import { defineComponent, onMounted, PropType, reactive, ref, toRefs, watch } from 'vue'
import { ContentLoader } from 'vue-content-loader';
import ApexChart from "apexcharts";

interface IDadoPacote {
    codConcessionaria: string;
    nomeFantasia: string;
    nprodutos: number;
    nrevisoes: number;
    premiacao: string;
    conversao: string;
    revisoes: string;
    corPercentual: string;
    metaconversao: string;
}

export default defineComponent({
    props: {
        dadosPacotes: {
            type: Array as PropType<Array<IDadoPacote>>
        },
        loading: {
            type: Boolean
        },
    },
    components: {
        ContentLoader
    },
	setup(props) {

	},
})
